.karriere {
    .karriereIntro {
        ul {
            margin-left: 0;
            list-style: none;
            li {
                background-image: url(../img/check.svg);
                background-repeat: no-repeat;
                background-position: left .3rem;
                background-size: 1rem;
                padding-left: 1.5rem;
            }
        }
    }
    .mitDunklemHintergrund {
        background-image: url(../img/hg_karriere.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: rem-calc(60);
    }
    form {
        color: $white;
        label {
            color: $white;
        }
        input,
        textarea {
            background: transparent;
            border-radius: 0.2rem;
            border-color: $white;
            color: get-color(primary);
        }
        .checkDatenschutz .wrapper {
            display: flex;
            input {
                margin-top: 0.25rem;
            }
        }
        label.primary {
            color: get-color(primary);
        }
        .button.primary {
            color: $white;
            border-radius: 0.2rem;
            font-weight: 500;
            letter-spacing: 0.1rem;
            margin-top: 1.5rem;
            margin-bottom: 2rem;
            padding: 0.5em 2.5em;
            border-color: transparent;
        }
    }
}
