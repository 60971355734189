.kundenmeinungen {
    background-image: url(../img/home-hg2.jpg);
    background-size: cover;
    padding-top: rem-calc(95);
    padding-bottom: rem-calc(70);
    color: $white;
    h2 {
        color: $white;
    }
    .kundenmeinung {
        font-style: italic;
        font-weight: 300;
        max-width: rem-calc(380);
        .kundenLogo {
            background-color: $white;
            width: rem-calc(190);
            height: rem-calc(190);
            border-radius: 50%;
            margin: 1rem auto 2rem auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            overflow: hidden;
        }
        blockquote {
            font-size: $global-font-size;
            margin: 0;
            padding: 0;
            border-left: none;
            color: $white;
        }
        cite {
            color: get-color(primary);
            font-size: $global-font-size;
            margin-top: .75rem;
        }
    }
}