body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
}

.index {
    .grid-x.homeIntro {
    flex-wrap: wrap;
    text-align: center;
    @include breakpoint(large) {
        text-align: left;
    }
        h1 {
            margin-top: 2.5rem;
        }
    }

    .leistungen {
        h2 {
            margin-top: rem-calc(94);
            margin-bottom: rem-calc(94);
        }
    }
    .leistungenList {
        .cell {
            flex-direction: column;
            padding-bottom: rem-calc(94);
        }
        h3 {
            font-weight: 300;
            margin-top: 1.5rem;
            margin-bottom: 0;
            text-align: center;
        }
        ul {
            list-style: none;
            margin-left: 0;
            li {
                background-image: url(../img/check.svg) ;
                background-repeat: no-repeat;
                background-position: left .3rem;
                background-size: rem-calc(20);
                padding-left: 1.5rem;
            }
        }
    }
}

