.mandat {
    .badge {
        position: absolute;
        top: -0.5rem;
        left: -1.5rem;
        display: inline-block;
        min-width: 3rem;
        padding: 0rem;
        font-weight: 900;
    }
}

.mandant {
    background-image: url(../img/ablaufmandat/hg_mandant.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;
    padding-top: 6rem;
    padding-bottom: 6rem;
    h2.light {
        font-weight: 300;
        text-transform: uppercase;
        font-size: 2rem;
        color: $white;
        line-height: 1;
        .bold {
            font-weight: 900;
        }
        @include breakpoint(medium) {
            font-size: 4rem;
        }
        @include breakpoint(xlarge) {
            font-size: rem-calc(112);
        }
    }
    .kundenmeinungen {
        background: transparent;
        .primary {
            color: $white;
        }
    }
    a.button.hollow.white {
        border-radius: 0.2rem;
        font-size: 1.5rem;
        font-weight: 300;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        margin-top: 3rem;
        margin-right: auto;
        margin-bottom: 5rem;
        margin-left: auto;
        display: block;
        width: fit-content;
        &:hover {
            color: get-color(secondary);
            border-color: get-color(secondary);
        }
        @include breakpoint(xlarge) {
            display: inline-block;
        }
    }
}
