footer {
    display: flex;
    color: $dark-gray;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    height: 137px;
    flex-direction: column;
    @include breakpoint(medium) {
        flex-direction: row;
    }
    p {
        margin-bottom: .3rem;
        text-align: center;
        .second {
            display: block;
            @include breakpoint(medium) {
                display: inline-block;
                text-align: left;
            }
        }
    }
    .menu {
        
        li {
            border-left: 1px solid transparent;
            border-right: 1px solid $dark-gray;
            margin-bottom: 0.5rem;
            a {
                color: $dark-gray;
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        li:last-child {
            border-right: 1px solid transparent;
        }
    }
}
