.ueberunsIntro {
    .imgwrapper {
        position: relative;
        img {
            @include breakpoint(large) {
                position: absolute;
                bottom: -1px;
            }
        }
    }
    ul {
        list-style: none;
        li {
            background-image: url(../img/check.svg);
            background-repeat: no-repeat;
            background-position: left .3rem;
            background-size: 1rem;
            padding-left: 1.5rem;
        }
    }
}
.team {
    background-color: get-color(secondary);
    background-image: url(../img/hg_unserteam.jpg);
    background-size: cover;
    color: $white;
    padding-top: rem-calc(90);
    padding-bottom: rem-calc(111);
    h2 {
        color: $white;
    }
    h2:nth-child(1) {
        margin-top: 2rem;
    }
    a.button.hollow.primary {
        margin-top: rem-calc(60);
        border-radius: 0.25rem;
        background-color: get-color(secondary);
        display: block;
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
        padding: 1.5em 2em;
        font-size: 1.2rem;
        @include breakpoint(xlarge) {
            display: inline-block;
        }
        &:hover {
            color: $white;
            border-color: $white;
        }
    }
}

.werte,
.mandat {
    margin-bottom: rem-calc(120);
    h2 {
        margin-top: rem-calc(110);
    }
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem-calc(138);
        width: rem-calc(138);
        border-radius: 50%;
        margin: 0 auto;
        position: relative;
    }
    h3 {
        color: get-color(primary);
        margin-top: rem-calc(21);
    }
    .strich {
        margin-bottom: rem-calc(21);
        margin-top: rem-calc(21);
    }
    .line {
        stroke: get-color(primary);
        stroke-width: 2;
    }
}
