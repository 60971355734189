#back-top {
    background: #ccc url(/assets/img/backtotop.png) no-repeat center center;
bottom: 20px;
cursor: pointer;
display: none;
height: 70px;
position: fixed;
right: 20px;
text-indent: -9999px;
width: 70px;
z-index: 999; }

#back-top:hover {
    opacity: 0.8;
}
