.index .homeIntro {
    margin-bottom: 4rem;
    svg {
        max-width: 100%;
    }
}

body.index .grid-x.homeIntro h1 {
    margin-top: 0;
}
.index.wenWirBeraten {
    background-image: url(../img/home-hg1.jpg);
    background-size: cover;
    color: $white;
    margin-top: 1.5rem;
    padding-top: rem-calc(132);
    padding-bottom: rem-calc(132);
    h2 {
        color: $white;
        margin-bottom: 2rem; 
    }
    .cards {
        margin-top: rem-calc(90); 
        .card {
            position: relative;
            overflow: visible;
            padding-bottom: rem-calc(25);
            box-shadow: 0 -2px 15px rgba(0,0,0,.6);
            .card-section {
                padding-right: rem-calc(10);
                padding-left: rem-calc(10);
                img.wenWir {
                    display: block;
                    max-width: 50%;
                    margin-top: rem-calc(25);
                    margin-right: auto;
                    margin-bottom: rem-calc(15);
                    margin-left: auto;
                }
            }
            img.weiterPfeil {
                display: block;
                position: absolute;
                width: rem-calc(40);
                bottom: rem-calc(-20);
                left: calc(50% - 20px);
            }
        }
    }
}

.index .leistungenList .cell {
    min-width: fit-content;
    color: $dark-gray;
}

.wenWirBeratenListe a {
    display: block;
    padding:.5rem;
    transition: all .3s;
    &:hover {
        padding: 0rem;
    }
} 

.illustrationWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}