.kontaktBlock {
    margin-top: 3rem;
    margin-bottom: 4rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    .cell {
        background-color: get-color(secondary);
        color: $white;
        margin-bottom: 1.5rem;
        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }
    h2 {
        color: get-color(primary);
        font-weight: 200;
        font-size: 1.5rem;
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
    p {
        padding: 0 .5rem;
    }
    a.button.primary {
        color: $white;
        border-radius: 0.2rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        margin-bottom: 2rem;
        padding: 0.5em 2.5em;
    }
}

.kontaktBlockVorForm {
    margin-bottom: 2rem;
    h2 {
        margin-bottom: 0;
    }
}

.kontakt form {
    input,
    textarea {
        border-radius: 0.2rem;
    }
    .checkDatenschutz .wrapper {
        display: flex;
        input {
            margin-top: .25rem;
        }
    }
    label.primary {
        color: get-color(primary);
    }
    .button.primary {
        color: $white;
        border-radius: 0.2rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        padding: 0.5em 2.5em;
    }
}
