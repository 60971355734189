.wenWirBeraten {
    @include breakpoint(xxlarge) {
    
    padding-bottom: rem-calc(111);
    }
    background-color: $white;

    h2 {
        margin-bottom: 2rem; 
    }

    .cards {
        @include breakpoint(xxlarge) {
        margin-top: rem-calc(90);
        }
        .card {
            position: relative;
            overflow: visible;
            padding-bottom: rem-calc(25);
            @include breakpoint(xxlarge) {
            margin-bottom: rem-calc(55);
            }
            box-shadow: 0 -2px 15px rgba(0,0,0,.6);
            max-width: 100%;
            margin-right: auto;
            margin-left: auto;
            .card-section {
                padding-right: rem-calc(10);
                padding-left: rem-calc(10);
                img.wenWir {
                    display: block;
                    max-width: 50%;
                    margin-top: rem-calc(25);
                    margin-right: auto;
                    margin-bottom: rem-calc(15);
                    margin-left: auto;
                }
            }
            img.weiterPfeil {
                display: block;
                position: absolute;
                width: rem-calc(40);
                bottom: rem-calc(-20);
                left: calc(50% - 20px);
            }
        }
    }
    .kundenarten {
        .slick-track {
            @include breakpoint(xxlarge) {
            width: 1335px !important;
            
            display: flex !important;
            justify-content: space-between;
            .slick-slide {
               width: 100% !important;
            }
        }
        }
        .card {
            box-shadow: none;
            border: none;
            .button {
                display: block;
                border-radius: .25rem;
                width: fit-content;
                margin: 0 auto;
                color: $black;
            }
        }
    }
}

.wenWirBeraten.mitDunklemHintergrund {
    background-image: url(../img/home-hg1.jpg);
    background-size: cover;
    background-position: center center;
}

.kundenblock .slick-list {
    padding: 0 !important;
    margin: 0 50px;

    
    
        // .slick-track {
        //     @include breakpoint(xxlarge) {
        //         width: 1335px !important;
        //         }
        // }
}

