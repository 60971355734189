$progress-indicator-complete: $primary-color;
$progress-indicator-current: $primary-color;
$progress-indicator-incomplete: $light-gray;
$progress-indicator-step-size: rem-calc(68);
$progress-indicator-width: 100%;

.anfrageProgress {
    margin-top: rem-calc(60);
    ul {
        list-style: none;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 1.5rem !important;
        & > li {
            position: relative;
            display: flex;
            .indicatorIcon {
                display: block;
                margin: 0 auto;
                height: rem-calc(69);
                min-width: rem-calc(69);
                &.icon1 {
                    background-image: url(../img/anfrage/1_grau.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    &.active {
                        background-image: url(../img/anfrage/1_blau.png);
                    }
                }
                &.icon2 {
                    background-image: url(../img/anfrage/2_grau.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    &.active {
                        background-image: url(../img/anfrage/2_blau.png);
                    }
                }
                &.icon3 {
                    background-image: url(../img/anfrage/3_grau.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    &.active {
                        background-image: url(../img/anfrage/3_blau.png);
                    }
                }
                &.icon4 {
                    background-image: url(../img/anfrage/check_grau.png);
                    background-size: 100%;
                    background-repeat: no-repeat;
                    &.active {
                        background-image: url(../img/anfrage/check_blau.png);
                    }
                }
            }
            &:nth-child(even) {
                width: 100%;
            }
            span {
                content: "";
                position: absolute;
                display: block;
                background: $progress-indicator-incomplete;
                width: 90%;
                height: rem-calc(1);
                top: 50%; // height of bar
                transform: translateY(-100%);
                left: 5%;
                z-index: 0;

                &.active {
                    background: $progress-indicator-complete;
                }
            }
        }
    }
}

.anfrageForm {
    transition: all .3s;
    fieldset {
        display: none;
    }
    .checkTab {
        margin: 0 0 0.8rem;
        label {
            scroll-margin-top: 0px;
        }
        input {
            appearance: none;
            scroll-margin-top: 0px;
            display: none;
        }
        input:checked + .check-section {
            background-color: get-color(primary);
        }
        .check-section {
            display: flex;
            flex-direction: column;
            background-color: get-color(secondary);
            color: $white;
            height: rem-calc(289);
            //padding-bottom: rem-calc(60);
            justify-content: center;
            transition: all .3s;
            img {
                margin: 0 auto rem-calc(30) auto;
            }
            &:hover,
            &.active {
                background-color: get-color(primary);
            }
        }
    }
    .anfrage_top {
        margin: rem-calc(60) 0;
        p {
            margin-bottom: 0;
            color: get-color(secondary);
        }
    }
    .finish {
        margin-top: rem-calc(60);
    }
    .anfrage__3,
    .anfrage__2_belege {
        .check-section {
            justify-content: center;
            padding-bottom: 0;
            h3 {
                margin-bottom: 0;
            }
        }
    }
    .uebernehmen {
        display: flex;
        position: relative;
        flex-direction: row;
        margin-top: rem-calc(144);
        .stroke-wrapper {
            position: relative;
            width: 100%;
        }
        .stroke {
            content: "";
            position: absolute;
            display: block;
            background: $progress-indicator-incomplete;
            width: 90%;
            height: rem-calc(1);
            top: 50%; // height of bar
            transform: translateY(-100%);
            left: 5%;
            z-index: 0;
        }
        .nxtButtonWrapper,
        .preButtonWrapper {
           
                display: flex;
                align-items: center;
        }
        figure {
            cursor: pointer;
        }
    }
    .button.primary {
        color: $white;
        border-radius: 0.2rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
        padding: 0.5em 2.5em;
    }
    label.primary {
        color: get-color(primary);
    }
}
