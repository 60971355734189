.digitaleBuchfuehrungBlocks {
    margin-top: 4rem;
    margin-bottom: 4rem;
    &.grid-padding-x > .cell {
        @include xy-gutters(3rem, padding, right left, false);
        transition: all .4s;
        &:hover {
            transform: scale(1.1);
        }
    }
    img {
        display: block;
        margin: 0 auto;
        height: 45px;
        width: auto;
    }
    h2 {
        font-weight: 300;
        margin-top: 1rem;
        margin-bottom: .5rem;
        color: $dark-gray;
        font-size: 1.3rem;
        width: 100%;
        text-align: center;
    }
    ul {
        list-style: none;
        margin-left: 0;
        @include breakpoint(large) {
            height: 0;
            opacity: 0;
            transition: all 1s;
        }
        &.fade-in {
            @include mui-animation(fade(0, 1));
            height: auto;
            opacity: 1;
        }
        li {
            background-image: url(../img/check.svg);
            background-repeat: no-repeat;
            background-position: left 0.3rem;
            background-size: 1rem;
            padding-left: 1.3rem;
        }
    }
}

.digitaleBuchfuehrungContent {
    display: grid;
    grid-template-areas:
        "navPapierloseZusammenarbeit"
        "contentPapierloseZusammenarbeit"
        "navDigitaleFinanzbuchhaltung"
        "contentDigitaleFinanzbuchhaltung"
        "navDigitaleLohnabrechnung"
        "contentDigitaleLohnabrechnung";
    @include breakpoint(large) {
        grid-template-areas:
            "navPapierloseZusammenarbeit navDigitaleFinanzbuchhaltung navDigitaleLohnabrechnung"
            "contentPapierloseZusammenarbeit contentPapierloseZusammenarbeit contentPapierloseZusammenarbeit"
            "contentDigitaleFinanzbuchhaltung contentDigitaleFinanzbuchhaltung contentDigitaleFinanzbuchhaltung"
            "contentDigitaleLohnabrechnung contentDigitaleLohnabrechnung contentDigitaleLohnabrechnung";
    }
    .contentNavItem.papierloseZusammenarbeit {
        grid-area: navPapierloseZusammenarbeit;
    }
    .contentNavItem.digitaleFinanzbuchhaltung {
        grid-area: navDigitaleFinanzbuchhaltung;
    }
    .contentNavItem.digitaleLohnabrechnung {
        grid-area: navDigitaleLohnabrechnung;
    }
    .papierloseZusammenarbeit.digitaleBuchfuehrungItem {
        grid-area: contentPapierloseZusammenarbeit;
    }
    .digitaleFinanzbuchhaltung.digitaleBuchfuehrungItem {
        grid-area: contentDigitaleFinanzbuchhaltung;
    }
    .digitaleLohnabrechnung.digitaleBuchfuehrungItem {
        grid-area: contentDigitaleLohnabrechnung;
    }
}

.contentNavItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
    }
    h3 {
        width: fit-content;
        display: block;
        margin: 0 auto 3rem auto;
        text-align: center;
    }
}

.digitaleBuchfuehrungItem {
    text-align: center;
    h2 {
        text-align: center;
        width: 100%;
    }
    h3 {
        font-size: 1rem;
        width: 100%;
        font-weight: 300;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    h4 {
        color: $white;
        text-align: left;
        font-weight: 300;
        font-size: rem-calc(31);
    }
    ul.details {
        display: grid;
        grid-template-columns: 1fr;
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        justify-content: center;
        justify-items: center;
        @include breakpoint(large) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            justify-items: unset;
        }
        list-style: none;
        margin: 0;
        li.tk {
            @include breakpoint(large) {
                background-image: url(../img/digitalebuchfuehrung/rahmen.svg);
                width: unset;
            }
            background-image: url(../img/digitalebuchfuehrung/rahmen_down.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            height: rem-calc(199);
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem 1.5rem;
            width: 300px;
            color: get-color(primary);
        }
        li.tu {
            @include breakpoint(large) {
                background-image: url(../img/digitalebuchfuehrung/rahmen_white.svg);
                width: unset;
                color: $white;
            }
            background-image: url(../img/digitalebuchfuehrung/rahmen_down_white.svg);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            height: rem-calc(199);
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1rem 1.5rem;
            width: 300px;
        }
    }

    #tuIndicator {
        background-color: $white;
        margin-right: .2rem;
    }
    #tkIndicator {
        background-color: get-color(primary);
        margin-right: .2rem;
        margin-left: .5rem;
    }
    .cell {
        max-width: 90vw;
        @include breakpoint(large) {
            max-width: auto;
        }
    }
    .text-small {
        text-align: center;
        padding-top: 1rem;
        display: inline-block;
        width: 100%;
        @include breakpoint(large) {
            text-align: left;
            display: flex;
            align-items: center;
        }
    }
}
