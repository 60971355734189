.main-content {
    .mitDunklemHintergrund {
        background-color: get-color(secondary);
        color: $white;
        padding-top: 4rem;
        padding-bottom: 4rem;
        h2 {
            color: $white;
        }
        .navItem,
        .contentNavItem {
            .icon {
                fill: $white;
                margin-top: 4rem;
                @include breakpoint(large) {
                    fill: get-color(primary);
                    margin-top: 0;
                }
                transition: all 0.3s;
            }
            h3 {
                color: get-color(primary);
                transition: all 0.3s;
            }
        }
        .contentNavItem h3 {
            display: none;
            @include breakpoint(large) {
                display: block;
            }
        }
        .navItem:hover,
        .navItem.active,
        .contentNavItem:hover,
        .contentNavItem.active {
            @include breakpoint(large) {
            cursor: pointer;
            }
            .icon {
                fill: $white;
            }
            h3 {
                color: $white;
            }
        }
    }
}
