.leistungenBlock {
    background-color: $light-gray;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 1rem;
    @include breakpoint(large) {
        margin: 4rem 0;
        }
    .leistungenList {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint(large) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    .leistungenList_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
        transition: all .4s;
        &:hover {
            transform: scale(1.1);
        }
        @include breakpoint(large) {
        margin: 3rem 0;
        }
        h3 {
            text-align: center;
            margin: 1rem 0;
            display: inline-block;
        }
        a.button.hollow.primary {
            border-radius: .2rem;
            color: $black;
            display: inline-block;
        }
    }
}

.leistungenList {
    display: flex;
    flex-flow: wrap;
    .cell {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2.7rem !important;
        min-width: rem-calc(326);
        transition: all .4s;
        &:hover {
            transform: scale(1.1);
        }
        @include breakpoint(medium) {
            align-items: center;
        }
        @include breakpoint(large) {
            align-items: center;
        }
        ul {
            max-width: 90%;
            margin-top: .8rem;
            font-size: .8rem;
            display: none;
            @include breakpoint(medium) {
                display: inline;
            }
            @include breakpoint(large) {
                height: 0;
                opacity: 0;
                transition: all 1s;
            }
            &.fade-in {
                @include mui-animation(fade(0, 1));
                height: auto;
                opacity: 1;
            }
            > li {
                opacity: 0;
                transition: all 1s;
                &.fade-in {
                    @include mui-animation(fade(0, 1));
                    opacity: 1;
                }
            }
        }
    }
    
}