.kundenBeratung {
    background-color: get-color(secondary);
    padding-top: rem-calc(95);
    padding-bottom: rem-calc(70);
    color: $white;
    scroll-margin-top: 120px;
    scroll-padding: 120px;
    h2 {
        color: $white;
        text-align: center;
    }
    .kundenBeratungArt {
        font-weight: 300;
        margin-bottom: 4rem;
        p {
            font-size: $global-font-size;
            margin: 0;
            padding: 0;
            border-left: none;
            color: $white;
        }
        .button {
            display: block;
            border-radius: .25rem;
            color: $white;
            margin: 2rem auto 0 auto;
            width: fit-content;
        }
    }
}