.rueckruf {
    form {
        input,
        textarea {
            border-radius: 0.2rem;
        }
        .checkDatenschutz .wrapper {
            display: flex;
            input {
                margin-top: .25rem;
            }
        }
        label.primary {
            color: get-color(primary);
        }
        .button.primary {
            color: $white;
            border-radius: 0.2rem;
            font-weight: 500;
            letter-spacing: 0.1rem;
            margin-top: 1.5rem;
            margin-bottom: 2rem;
            padding: 0.5em 2.5em;
        }
    }
}