.hero {
  // display: flex;
  //   justify-content: stretch;
    height: max-content;
    &> img {
      position: relative;
      width: 100%;
        box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.81) inset;
        -webkit-box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.81) inset;
        -moz-box-shadow: 0px 5px 6px 0px rgba(0,0,0,0.81) inset;
    }
}

.hero {
  z-index: 4;
  margin-bottom: rem-calc(0);
  @include breakpoint(medium) {
    margin-bottom: rem-calc(0);
  }
  @include breakpoint(xxlarge) {
    margin-bottom: rem-calc(90);
  }
  }

