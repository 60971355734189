/* ubuntu-regular - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: normal;
     font-weight: 400;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-regular.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-300 - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: normal;
     font-weight: 300;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-300.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-300italic - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: italic;
     font-weight: 300;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-300italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-italic - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: italic;
     font-weight: 400;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-500 - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: normal;
     font-weight: 500;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-500.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-500italic - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: italic;
     font-weight: 500;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-500italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-700 - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: normal;
     font-weight: 700;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-700.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-700italic - latin */
@font-face {
     font-family: "Ubuntu";
     font-style: italic;
     font-weight: 700;
     font-display: swap;
     src: local(""), url("../fonts/ubuntu-v15-latin-700italic.woff2") format("woff2"),
          /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/ubuntu-v15-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
     h1,
     h2,
     h4,
     h5,
     h6 {
          font-weight: 600;
          color: get-color(secondary);
          margin-bottom: 1.5rem;
     }

     h1 {
          font-weight: 600;
          color: get-color(secondary);
          margin-bottom: 1.5rem;
          font-size: rem-calc(28);
          @include breakpoint (medium) {
               font-size: rem-calc(43);
          }
     }

     h3 {
          font-weight: 300;
     }

     span.primary {
          color: get-color(primary);
     }

     .text-small {
          font-size: 0.6rem;
     }
}

.checkLists {
     width: 100%;
     justify-content: space-evenly;
     margin-top: 4rem;
     ul.check {
          list-style: none;
          margin-left: 0;
          max-width: 100%;
          li {
               background-image: url(../img/check.svg);
               background-repeat: no-repeat;
               background-position: left 0.3rem;
               background-size: rem-calc(20);
               padding-left: 1.5rem;
               text-align: left;
          }
     }
}

.digitaleLohnabrechnung.digitaleBuchfuehrungItem ul.check {
     max-width: 90vw;
     li {
          @include breakpoint(large) {
               max-width: 100%;
          }
     }
}

.mb-2 {
     margin-bottom: 2rem;
}
