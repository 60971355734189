

.mainburger {
    cursor: pointer;
    width: 30px;
    height: 25px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .mainburger i {
    background-color:get-color(secondary);
    content: "";
    display: block;
    width: 100%;
    height: 4px;
  }
  .mainburger i:nth-child(1) {
    -webkit-animation: outT 0.8s backwards;
    animation: outT 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }
  .mainburger i:nth-child(2) {
    margin: 5px 0;
    -webkit-animation: outM 0.8s backwards;
    animation: outM 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }
  .mainburger i:nth-child(3) {
    -webkit-animation: outBtm 0.8s backwards;
    animation: outBtm 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
  }
  .mainburger.active i:nth-child(1) {
    -webkit-animation: inT 0.8s forwards;
    animation: inT 0.8s forwards;
  }
  .mainburger.active i:nth-child(2) {
    -webkit-animation: inM 0.8s forwards;
    animation: inM 0.8s forwards;
  }
  .mainburger.active i:nth-child(3) {
    -webkit-animation: inBtm 0.8s forwards;
    animation: inBtm 0.8s forwards;
  }


@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

.burgerwrap {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint(small) {
    height: 100%;
    width: 57px;
  }
  @include breakpoint(large) {
    height: 114px;
    width: 114px;
  }
}

.navwrapper {
  @include breakpoint(small) {
    margin: auto;
    width: 100vw;
  }
  @include breakpoint(large) {
    @include xy-cell-base(auto);
    height: auto;
    margin: auto;
    width: auto;
    position: relative;
  }
}

.mainmenu {
  @include breakpoint(small) {
    @include xy-cell-base(full);
    display: none;
    margin: auto;
    background-color: $white;
    position: absolute;
    margin-top: 0;
    z-index: 105;
    width: 100vw;
    height: 100vh;
    margin-right: 0;
    top: 105px;
    left: 0;
  }
  @include breakpoint(large) {
    @include xy-cell-base(auto);
    display: flex;
    margin: auto;
    background-color: transparent;
    position: relative;
    margin-top: 0;
    width: auto;
    height: auto;
    top: unset;
    left: unset;
  }
}

.mainmenu ul {
  @include breakpoint(small) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    margin: 0;
  }
  @include breakpoint(large) {
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding-right: 0;
    margin: 0 auto;
    width: 100%;
  }
}

.mainmenu a {
  @include breakpoint(small) {
    color: $white;
    padding: 0.75rem 0.5rem;
  }
  @include breakpoint(large) {
    color: $black;
    padding: 0.75rem 0 !important;
  }
}

ul.submenu {
  list-style-type: none;
  list-style-image: none;
  margin-left: 0;
  transition: all 0.3s;
}

.is-dropdown-submenu {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  width: auto;
  min-width: fit-content;
  width: fit-content !important;
  transition: all 0.3s;
  a {
    padding: 0.75rem 0.5rem;
  }
}
.dropdown.menu li a {
  background: rgba(255, 255, 255, 0);
  transition: all 0.3s;
}

.dropdown.menu li a:hover,
.dropdown.menu li a:focus,
.dropdown.menu li a.is-active {
  background: rgba(255, 255, 255, 0.7);
}

.drilldown a {
  padding: 0.7rem 2rem;
  background: transparent;
}

.mainmenu .drilldown .is-drilldown-submenu {
  background: $black;
  text-align: right;
}

.mainmenu .drilldown .is-drilldown-submenu-parent > a::after {
  color: $white;
}
