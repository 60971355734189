html {
    scroll-padding-top: 120px;
    scroll-behavior: smooth;
}

header {
    border-top: 9px solid get-color(secondary);
    height: 114px;
    max-width: 2350px;
    margin-left: auto;
    margin-right: auto;
    background-color: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    z-index: 5;
    & > .grid-container.fluid {
        max-width: 2350px;
    }
    .grid-x {
        height: 109px;
    }

    .logocell {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo object svg {
        max-width: 250px;
        @include breakpoint(medium) {
            max-width: unset;
        }
    }
}

.navwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    ul.menu {
        justify-content: space-evenly;
        & > li {
            font-size: inherit;
            @include breakpoint(large) {
                font-size: rem-calc(12);
            }
            @include breakpoint(xlarge) {
                font-size: rem-calc(12);
            }
            a {
                color: $dark-gray;
                font-weight: 500;
                text-transform: uppercase;
                @include breakpoint(xlarge) {
                    font-size: rem-calc(12);
                    padding: 0.35rem 0.2rem;
                }
                @include breakpoint(xlarge) {
                    font-size: rem-calc(14);
                    padding: 0.35rem 0.2rem;
                }
                @include breakpoint(xxlarge) {
                    font-size: rem-calc(17);
                    padding: 0.35rem 0.2rem;
                }
                @include breakpoint(xxxlarge) {
                    font-size: rem-calc(20);
                    padding: 0.35rem 0.2rem;
                }
            }
            a.active {
                color: get-color(secondary);
            }
            a:hover {
                color: get-color(primary);
            }
            .submenu a {
                @include breakpoint(xlarge) {
                    font-size: rem-calc(10);
                    padding: 0.35rem 0.2rem;
                }
                @include breakpoint(xlarge) {
                    font-size: rem-calc(12);
                    padding: 0.35rem 0.2rem;
                }
                @include breakpoint(xxlarge) {
                    font-size: rem-calc(14);
                    padding: 0.35rem 0.2rem;
                }
                @include breakpoint(xxxlarge) {
                    font-size: rem-calc(17);
                    padding: 0.35rem 0.2rem;
                }
            }
        }
    }
    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
        display: block;
        width: 0;
        height: 0;
        border: inset 6px;
        content: "";
        border-bottom-width: 0;
        border-top-style: solid;
        border-color: #0091c0 transparent transparent;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        right: unset;
        left: 0px;
    }
    .accordion-menu li {
        width: auto;
    }

    .is-accordion-submenu-parent {
        a {
        }
        ul li:first-child {
            display: none;
        }
    }
    .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
    }
    .is-accordion-submenu {
        text-align: center;
        a {
            font-size: rem-calc(17);
        }
    }
    .dropdown.menu.vertical > li.opens-right > a::after {
        display: none !important;
    }
    .dropdown .is-dropdown-submenu a {
        padding: 0.7rem 1rem !important;
    }

    .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
        top: 100%;
        right: auto;
        left: -1rem;
        border: none;
    }
}

.headerPhone {
    font-size: rem-calc(37);
    font-weight: 500;
    padding-left: 62px;
    border-left: 3px solid get-color(secondary);
    height: 78px;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    @include breakpoint(large) {
        font-size: rem-calc(12);
        padding-left: 15px;
        height: 40px;
    }
    @include breakpoint(xlarge) {
        font-size: rem-calc(16);
        padding-left: 4px;
        height: 40px;
        border-left: 2px solid get-color(secondary);
        margin-right: -8px;
    }
    @include breakpoint(xxlarge) {
        font-size: rem-calc(18);
        padding-left: 12px;
        height: 50px;
    }
    @include breakpoint(xxxlarge) {
        font-size: rem-calc(37);
        padding-left: 62px;
        height: 78px;
    }
}
