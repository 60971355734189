.leistungen {
    h1 {
        text-align: center;
    }
    .homeIntro {
        p {
            text-align: center;
        }
    }
    .leistungenBlock {
        background-color: $white;
        margin-top: 0;
        h2 {
            display: none;
        }
    }
}

.leistungenButton a.button.primary {
    color: $white;
    border-radius: .2rem;
    margin-top: 3rem;
    font-weight: 500;
    letter-spacing: .1rem;
}