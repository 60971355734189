.kontaktButtons {
    position: fixed;
    list-style: none;
    right: 0px;
    color: get-color(primary);
    text-transform: uppercase;
    z-index: 8;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    padding: 0;
    transition: none;
    top: 114px;
    @include breakpoint(large) {
        font-size: rem-calc(10);
        margin-left: rem-calc(-72);
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: get-color(secondary);
            letter-spacing: 0.1rem;
            width: rem-calc(72);
            height: rem-calc(68);
            transition: all 0.5s;
            position: relative;
            overflow: hidden;

            img {
                margin-bottom: rem-calc(5);
            }
            &.anfragen img {
                width: rem-calc(21);
            }
            &.rueckruf img {
                width: rem-calc(24);
            }
            &.kontakt img {
                width: rem-calc(26);
            }

            span {
                height: auto;
            }
            &.small {
                width: rem-calc(40);
                height: rem-calc(40);
                img {
                    margin-bottom: rem-calc(0);
                }
                span {
                    position: relative;
                    transform: translate(50px, 100px);
                }
            }
        }
    }
    @include breakpoint(xlarge) {
        font-size: rem-calc(12);
        margin-left: rem-calc(-98);
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: get-color(secondary);
            letter-spacing: 0.1rem;
            width: rem-calc(98);
            height: rem-calc(98);
            transition: all 0.5s;
            position: relative;
            overflow: hidden;

            img {
                margin-bottom: rem-calc(14);
            }
            &.anfragen img {
                width: rem-calc(32);
            }
            &.rueckruf img {
                width: rem-calc(38);
            }
            &.kontakt img {
                width: rem-calc(43);
            }
            &.small {
                width: rem-calc(60);
                height: rem-calc(60);
                img {
                    margin-bottom: rem-calc(0);
                }
                span {
                    position: relative;
                    transform: translate(50px, 100px);
                }
            }
        }
    }
    @include breakpoint(xxxlarge) {
        font-size: rem-calc(15);
        margin-left: rem-calc(-158);
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: get-color(secondary);
            letter-spacing: 0.2rem;
            width: rem-calc(158);
            height: rem-calc(158);
            transition: all 0.5s;
            position: relative;
            overflow: hidden;

            img {
                margin-bottom: rem-calc(21);
            }
            &.anfragen img {
                width: rem-calc(42);
            }
            &.rueckruf img {
                width: rem-calc(48);
            }
            &.kontakt img {
                width: rem-calc(53);
            }
            &.small {
                width: rem-calc(70);
                height: rem-calc(70);
                img {
                    margin-bottom: rem-calc(0);
                }
                span {
                    position: relative;
                    transform: translate(50px, 100px);
                }
            }
        }
    }
}
