.kontakt,
.ueber-uns,
.rueckruf {
    .contactInfoWrapper {
        color: $white;
        background-color: get-color(secondary);
        h2 {
            color: $white;
        }
    }
}

.contactInfoWrapper {
    .contactInfoHeader {
        padding: 50px 0;
        h2 {
            margin-bottom: 0;
        }
    }
    .googleMap {
        background-color: get-color(secondary);
    }
    .contactInfoDataWrapper {
        background-color: get-color(secondary);
        .contactInfoData {
            color: $white;
            padding-top: 50px;
            padding-bottom: 50px;
            justify-content: space-between;
            .contactInfo_Item {
                margin-bottom: 1.5rem;
                margin-left: 10%;
                    border-left: 1px solid $white;
                @include breakpoint(large) {
                    border-left: 1px solid $white;
                    margin-bottom: 0;
                    margin-left: 0;
                }
                h3 {
                    color: get-color(primary);
                    font-weight: 300;
                    font-size: rem-calc(31);
                }
                p {
                    margin-bottom: 0;
                }
                .tel,
                .fax,
                .email {
                    padding-left: 2rem;
                    background-repeat: no-repeat;
                    height: 23px;
                }
                .tel {
                    display: inline-block;
                    background-image: url(../img/tel.svg);
                    margin-bottom: .7rem;
                }
                .fax {
                    background-image: url(../img/fax.svg);
                    margin-left: 0;
                    display: block;
                    
                    margin-bottom: .7rem;
                    @include breakpoint(large) {
                        display: inline;
                        margin-left: 2rem;
                    }
                }
                .email {
                    background-image: url(../img/email.svg);
                }
            }
        }
    }
}
