.headerWrapper,
.kontaktButtons {
    z-index: 99;
}

.hero {
    z-index: 80;
}

.main-content {
    z-index: 70;
}

.contactInfoWrapper {
    z-index: 60;
}

.back-top {
    z-index: 98;
}